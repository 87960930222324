import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import faq from '../pictures/faq.svg';

const Home = () => {
  return (
    <div>
      <div className="container d-flex justify-content-center">
      <div style={{
          width: "833px",
          height: "685px"
        }}>

          <p className='d-flex justify-content-center align-items-center fw-bold' style={{height:"200px",fontSize:"40px",backgroundImage:`url(${faq})`,backgroundSize:"cover"}}>FAQ's</p>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header><p style={{fontSize:"18px",fontWeight:"600"}}>How to attempt test? </p></Accordion.Header>
              <Accordion.Body>
              You can attempt different challenges or create your own test.
              
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><p style={{fontSize:"18px",fontWeight:"600"}}>How Testwin works? </p></Accordion.Header>
              <Accordion.Body>
              The learners on the platform can create test and challenge other students,
               those getting highest marks in these tests or scholarship tests conducted 
               by Testwin are rewarded with prizes, in the process student undergo peer 
               to peer learning and also can choose the subject of their doubt and can get 
               connected with teachers on a click of button.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><p style={{fontSize:"18px",fontWeight:"600"}}>Can i Withdraw funds?</p></Accordion.Header>
              <Accordion.Body>
                Undergo free KYC process and get the amount transferred to your bank account within 6 hours.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header><p style={{fontSize:"18px",fontWeight:"600"}}>How can i earn more cash rewards?</p></Accordion.Header>
              <Accordion.Body>
              Testwin is Artificial Intelligence driven learning platform which rewards students at each
              stage of their exam preparation. Get started with learning along with rewards by challenging
              your friends. Let's Test it out.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header><p style={{fontSize:"18px",fontWeight:"600"}}>Why withdrawal request got rejected or taking time to process?</p></Accordion.Header>
              <Accordion.Body>
                It can be some server issue , Please try after some time.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header><p style={{fontSize:"18px",fontWeight:"600"}}>How to create Test?</p></Accordion.Header>
              <Accordion.Body>
                You can create your own test by first clicking on create test and then selecting the desired
                course and subject and then you can play the test.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      
   </div>
  );
}

export default Home;