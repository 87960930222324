import React from 'react'
import blog from '../pictures/blog.jpg'
import blogwriter from '../pictures/blogwriter.svg'
import readourblogs from '../pictures/readourblogs.svg'
import arrow from '../pictures/arrow.svg'

function Blogs() {
    return (
        <>
        <div className='d-flex flex-column justify-content-center align-items-center'>
        <div className='fw-bold mt-5' style={{fontSize:"40px"}}>Read our blogs</div>
        <div><img src={readourblogs}/></div>
        </div>
        <div style={{ margin: "50px" }}>
          
            <div class="row row-cols-1 row-cols-md-3 g-4">
                <div class="col">
                    <div class="card h-100">
                        <img src={blog} class="card-img-top" alt="..." />
                        <div class="card-body">
                            <h5 class="card-title">New way to solve Pythagorean Theorem</h5>
                            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                        <div class="card-footer">
                            <div class="text-muted d-flex justify-content-between">
                            <div>
                                 <img src={blogwriter}/> 
                                <span> Aman Sindal</span>
                                </div>
                                
                               <a href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US"> <button style={{background:"#7F01FC",color:"white",borderRadius:"5px",border:"none"}}>Read More</button></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <img src={blog} class="card-img-top" alt="..." />
                        <div class="card-body">
                            <h5 class="card-title">New way to solve Pythagorean Theorem</h5>
                            <p class="card-text">This is a short card.</p>
                        </div>
                        <div class="card-footer">
                            <div class="text-muted d-flex justify-content-between">
                            <div>
                                 <img src={blogwriter}/> 
                                <span> Aman Sindal</span>
                                </div>
                                
                              <a href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US">  <button style={{background:"#7F01FC",color:"white",borderRadius:"5px",border:"none"}}>Read More</button></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <img src={blog} class="card-img-top" alt="..." />
                        <div class="card-body">
                            <h5 class="card-title">New way to solve Pythagorean Theorem</h5>
                            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
                        </div>
                        <div class="card-footer">
                            <div class="text-muted d-flex justify-content-between">
                               <div>
                                 <img src={blogwriter}/> 
                                <span> Aman Sindal</span>
                                </div>
                                
                              <a href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US"> <button style={{background:"#7F01FC",color:"white",borderRadius:"5px",border:"none"}}>Read More</button></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='d-flex justify-content-center align-items-center fw-bold'><a style={{color:"black"}} href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US">View all</a> 
            <span><img src={arrow}/></span>
            </div>
        </>
    )
}

export default Blogs