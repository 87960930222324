import React from 'react';
import '../css/partners.css'
import partner1 from '../pictures/partner1.svg';
import partner2 from '../pictures/partner2.svg';
import partner3 from '../pictures/partner3.svg';
import partner4 from '../pictures/partner4.svg';

function Partners() {
  return (
    <>
    <div className='partnerWrapper d-flex flex-wrap align-items-center justify-content-between' style={{padding:"80px 80px" }}>
          <div className='partnerText' style={{fontSize:"25px",fontWeight:"300"}}> Our Reward Partners: </div>
          <div className='m-3 colorhover'> <img src={partner1}/></div>
          <div className='m-3 colorhover'> <img src={partner2}/></div>
          <div className='m-3 colorhover'> <img src={partner3}/></div> 
          <div className='m-3 colorhover'> <img src={partner4}/></div> 
    </div>
    </>
  )
}

export default Partners
// -webkit-filter: grayscale(100%)
//   filter: grayscale(100%)