import React from 'react'

function TermsAndConditions() {
  return (
    <div style={{ marginTop: "70px", }}>
      <div style={{ padding: "30px", margin: "20px", background: "#F7F5FB",borderRadius:"50px" }}>
        <h2>Terms and conditions:</h2>
        <h3 style={{ marginTop: "40px" }}>1. Testwin</h3>
        <p>TestWin is the flagship brand of Wolooka Technologies Private Limited ("TestWin"). TestWin operates a mobile application through which it offers knowledge-based online Tests/Courses/Challenges/Offers. TestWin, as used herein, shall be construed as a collective reference to TestWin and the TestWin App.
        </p>
        <h3>2. Usage of Testwin</h3>
        <p><ul>
          <li>Any person ("User") accessing TestWin or the TestWin App ('TestWin platform') for participating in the various challenges, available on the TestWin platform shall be bound by these Terms and Conditions, and all other rules, regulations, and terms of use referred to herein or provided by TestWin concerning any TestWin Services.
          </li>
          <li>TestWin shall be entitled to modify these Terms and Conditions, rules, regulations, and terms of use referred to herein or provided by TestWin concerning any TestWin Services, at any time, by posting the same on TestWin. Use of TestWin constitutes the User's acceptance of such Terms and Conditions, rules, regulations, and terms of use referred to herein or provided by TestWin about any TestWin Services, as may be amended from time to time. TestWin may, at its sole discretion, also notify the User of any change or modification in these Terms and Conditions, rules, regulations, and terms of use referred to herein or provided by TestWin, by way of sending an email to the User's registered email address or posting notifications in the User accounts. The User may then exercise the options provided in such an email or notification to indicate non-acceptance of the modified Terms and Conditions, rules, regulations, and terms of use referred to herein or provided by TestWin. If such options are not exercised by the User within the time frame prescribed in the email or notification, the User will be deemed to have accepted the modified Terms and Conditions, rules, regulations, and terms of use referred to herein or provided by TestWin.
          </li>
          <li>Certain TestWin Services being provided on TestWin may be subject to additional rules and regulations set down in that respect. To the extent that these Terms and Conditions are inconsistent with the additional conditions set down, the additional conditions shall prevail
          </li>
          <li>TestWin may, at its sole and absolute discretion:
            <ol> <li>Restrict, suspend, or terminate any User's access to all or any part of TestWin or TestWin Platform Services;
            </li>
              <li>Change, suspend, or discontinue all or any part of the TestWin Platform Services;
              </li>
              <li>Reject, move, or remove any material that may be submitted by a User;
                Move or remove any content that is available on the TestWin Platform;
              </li>
              <li>Deactivate or delete a User's account and all related information and files on the account;</li>
              <li>Establish general practices and limits concerning the use of the TestWin Platform;</li>
              <li>Offer discounts to its users in the form it deems fit ("Cash Bonus/Referral Rupee/Vouchers/offers/Testwin Coins"). All such discounts shall be credited to a separate section called Earn Section or Wallet</li>
              <li>Revise or make additions and/or deletions to the roster of players available for selection in a Challenge on account of revisions to the roster of players involved in the relevant Knowledge based Challenge;</li>
              <li>Assign its rights and liabilities to all User accounts hereunder to any entity (post such assignment intimation of such assignment shall be sent to all Users to their registered email ids)
              </li>
            </ol>
          </li>
          <li>In the event any User breaches or TestWin reasonably believes that such User has breached these Terms and Conditions, or has illegally or improperly used TestWin or the TestWin Services, TestWin may, at its sole and absolute discretion, and without any notice to the User, restrict, suspend or terminate such User's access to all or any part of TestWin Challenges or the TestWin Platform, deactivate or delete the User's account and all related information on the account, delete any content posted by the User on TestWin and further, take technical and legal steps as it deems necessary
          </li>
          <li>If TestWin charges its Users a platform fee in respect of any TestWin Services, TestWin shall, without delay, repay such platform fee in the event of suspension or removal of the User's account or TestWin Services on account of any negligence or deficiency on the part of TestWin, but not if such suspension or removal is affected due to:
            <ol>
              <li>any breach or inadequate performance by the User of any of these Terms and Conditions; or</li>
              <li>any circumstances beyond the reasonable control of TestWin.
              </li>
            </ol>
          </li>
          <li>Users consent to receiving communications such as announcements, administrative messages, and advertisements from TestWin or any of its partners, licensors, or associates.
          </li>
        </ul></p>
        <h3>3. Intellectual Property</h3>
        <ul>
          <li>TestWin includes a combination of content created by TestWin, its partners, affiliates, licensors, associates, and/or Users. The intellectual property rights ("Intellectual Property Rights") in all software underlying TestWin and the TestWin Platform and material published on TestWin, including (but not limited to) games, Contests, software, advertisements, written content, photographs, graphics, images, illustrations, marks, logos, audio or video clippings and Flash animation, is owned by TestWin, its partners, licensors and/or associates. Users may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works of, distribute, publicly perform, publicly display, or in any way exploit any of the materials or content on TestWin either in whole or in part without an express written license from TestWin</li>
          <li>Users may request permission to use any TestWin content by writing into TestWin support@testwin.in
          </li>
          <li>Users are solely responsible for all materials (whether publicly posted or privately transmitted) that they upload, post, e-mail, transmit, or otherwise make available on TestWin ("Users' Content"). Each User represents and warrants that he/she owns all Intellectual Property Rights in the User's Content and that no part of the User's Content infringes any third party rights. Users further confirm and undertake to not display or use the names, logos, marks, labels, trademarks, copyrights, or intellectual and proprietary rights of any third party on TestWin. Users agree to indemnify and hold harmless TestWin, its directors, employees, affiliates and assigns against all costs, damages, loss, and harm including litigation costs and counsel fees, in respect of any third-party claims that may be initiated including for infringement of Intellectual Property Rights arising out of such display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on TestWin, by such User or through the User's commissions or omissions
          </li>
          <li>Users hereby grant to TestWin and its affiliates, partners, licensors, and associates a worldwide, irrevocable, royalty-free, non-exclusive, sub-licensable license to use, reproduce, create derivative works of, distribute, publicly perform, publicly display, transfer, transmit, and/or publish Users' Content for any of the following purposes:
            <ol>
              <li>displaying Users' Content on TestWin</li>
              <li>distributing Users' Content, either electronically or via other media, to other Users seeking to download or otherwise acquire it, and/or
              </li>
              <li>storing Users' Content in a remote database accessible by end users, for a charge.</li>
              <li>This license shall apply to the distribution and storage of Users' Content in any form, medium, or technology.
              </li>
            </ol>
          </li>
          <li>All names, logos, marks, labels, trademarks, copyrights, or intellectual and proprietary rights on TestWin(s) belonging to any person (including User), entity, or third party are recognized as proprietary to the respective owners, and any claims, controversy or issues against these names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights must be directly addressed to the respective parties under notice to TestWin.
          </li>
        </ul>
        <h3>4. Third-Party Sites, Services, and Products</h3>
        <ul>
          <li>TestWin may contain links to other Internet sites owned and operated by third parties. Users' use of each of those sites is subject to the conditions, if any, posted by the sites. TestWin does not exercise control over any Internet sites apart from TestWin and cannot be held responsible for any content residing on any third-party Internet site. TestWin's inclusion of third-party content or links to third-party Internet sites is not an endorsement by TestWin of such third-party Internet sites.
          </li>
          <li>User's correspondence, transactions/offers, or related activities with third parties, including payment providers and verification service providers, are solely between the User and that third party. User's correspondence, transactions, and usage of the services/offers of the such third party shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such third party, and the User shall be solely responsible for reviewing the same before transacting or availing of the services/offers of such third party. The user agrees that TestWin will not be responsible or liable for any loss or damage of any sort incurred as a result of any such transactions/offers with third parties. Any questions, complaints, or claims related to any third-party product or service should be directed to the appropriate vendor.
          </li>
          <li>TestWin contains content that is created by TestWin as well as content provided by third parties. TestWin does not guarantee the accuracy, integrity, or quality of the content provided by third parties, and such content may not be relied upon by the Users in utilizing the TestWin Services provided on TestWin including while participating in any of the challenges hosted on TestWin.
          </li>
        </ul>
        <h3>5. Privacy Policy</h3>
        <p>All information collected from Users, such as registration and credit card information, is subject to TestWin's Privacy Policy which is available in the Privacy Policy section. </p>
        <h3>6. User Conduct</h3>
        <ul>
          <li>Users agree to abide by these Terms and Conditions and all other rules, regulations, and terms of use of the Website. In the event, User does not abide by these Terms and Conditions and all other rules, regulations, and terms of use, TestWin may, at its sole and absolute discretion, take necessary remedial action, including but not limited to:
            <ol>
              <li>restricting, suspending, or terminating any User's access to all or any part of TestWin Services</li>
              <li>deactivating or deleting a User's account and all related information and files on the account. Any amount remaining unused in the User's Game account or Winnings Account on the date of deactivation or deletion shall be transferred to the User's bank account on record with TestWin subject to a processing fee (if any) applicable on such transfers as set out herein; or
              </li>
              <li>refraining from awarding any coin(s) to such User.
              </li>
            </ol>
          </li>
          <li>Users agree to provide true, accurate, current, and complete information at the time of registration and at all other times (as required by TestWin). Users further agree to update and keep updated their registration information
          </li>
          <li>A User shall not register or operate more than one User account with TestWin.
          </li>
          <li>Users agree to ensure that they can receive all communication from TestWin by marking e-mails or sending SMSs from TestWin as part of their "safe senders" list. TestWin shall not be held liable if any e-mail/SMS remains unread by a User as a result of such e-mail getting delivered to the User's junk or spam folder.
          </li>
          <li>Any password issued by TestWin to a User may not be revealed to anyone else. Users may not use anyone else's password. Users are responsible for maintaining the confidentiality of their accounts and passwords. Users agree to immediately notify TestWin of any unauthorized use of their passwords or accounts or any other breach of security.
          </li>
          <li>Users agree to exit/log-out of their accounts at the end of each session. TestWin shall not be responsible for any loss or damage that may result if the User fails to comply with these requirements.
          </li>
          <li>Users agree not to use cheats, exploits, automation, software, bots, hacks, or any unauthorized third-party software designed to modify or interfere with TestWin Services and/or TestWin experience or assist in such activity.
          </li>
          <li>Users agree not to copy, modify, rent, lease, loan, sell, assign, distribute, reverse engineer, grant a security interest in, or otherwise transfer any right to the technology or software underlying TestWin or TestWin’s Services.
          </li>
          <li>Users agree that without TestWin's express written consent, they shall not modify or cause to be modified any files or software that are part of TestWin's Services.
          </li>
          <li>Users agree not to disrupt, overburden, or aid or assist in the disruption or overburdening of (a) any computer or server used to offer or support TestWin or the TestWin’s Services (each a "Server"); or (2) the enjoyment of TestWin Services by any other User or person.
          </li>
          <li>Users agree not to institute, assist or become involved in any type of attack, including without limitation to distribution of a virus, denial of service, or other attempts to disrupt TestWin Services or any other person's use or enjoyment of TestWin Services.
          </li>
          <li>Users shall not attempt to gain unauthorized access to the User accounts, Servers, or networks connected to TestWin Services by any means other than the User interface provided by TestWin, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that underlies or is part of TestWin Services.
          </li>
          <li>Users shall not publish any content that is patently false and untrue and is written or published in any form, with the intent to mislead or harass a person, entity, or agency for financial gain or to cause any injury to any person.
          </li>
          <li>Without limiting the foregoing, Users agree not to use TestWin for any of the following:
            <ol>
              <li>To engage in any obscene, offensive, indecent, racial, communal, anti-national, objectionable, defamatory, or abusive action or communication;
              </li>
              <li>To harass, stalk, threaten, or otherwise violate any legal rights of other individuals;
              </li>
              <li>To publish, post, upload, e-mail, distribute, or disseminate (collectively, "Transmit") any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful content;
              </li>
              <li>To Transmit files that contain viruses, corrupted files, or any other similar software or programs that may damage or adversely affect the operation of another person's computer, TestWin, any software, hardware, or telecommunications equipment;
              </li>
              <li>To advertise, offer or sell any goods or services for any commercial purpose on TestWin without the express written consent of TestWin;
              </li>
              <li>To download any file, recompile or disassemble or otherwise affect our products that you know or reasonably should know cannot be legally obtained in such manner;
              </li>
              <li>To falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or the source of software or other material;
              </li>
              <li>To restrict or inhibit any other user from using and enjoying any public area within our sites;
              </li>
              <li>To collect or store personal information about other Users;
              </li>
              <li>To interfere with or disrupt TestWin, servers, or networks;
              </li>
              <li>To impersonate any person or entity, including, but not limited to, a representative of TestWin, or falsely state or otherwise misrepresent the User's affiliation with a person or entity;
              </li>
              <li>To forge headers or manipulate identifiers or other data to disguise the origin of any content transmitted through TestWin or to manipulate the User's presence on TestWin(s);
              </li>
              <li>To take any action that imposes an unreasonably or disproportionately large load on our infrastructure;
              </li>
              <li>To engage in any illegal activities. You agree to use our bulletin board services, chat areas, news groups, forums, communities, and/or message or communication facilities (collectively, the "Forums") only to send and receive messages and material that are proper and related to that particular Forum.</li>
              <li>To engage in any activity that threatens the unity, integrity, defense, security, or sovereignty of India, friendly relations with foreign states, or public order, or causes incitement to the commission of any cognizable offense or prevents investigation of any offense or is insulting other nations.
              </li>
            </ol>
          </li>
          <li>If a User chooses a username that, in TestWin's considered opinion is obscene, indecent, abusive or that might subject TestWin to public disparagement or scorn, or a name that is an official team/league/franchise name and/or name of any sporting personality, as the case may be, TestWin reserves the right, without prior notice to the User, to restrict usage of such names, which in TestWin’s opinion fall within any of the said categories and/or change such username and intimate the User or delete such username and posts from TestWin, deny such User access to TestWin or any combination of these options.
          </li>
          <li>Unauthorized access to TestWin is a breach of these Terms and Conditions, and a violation of the law. Users agree not to access TestWin by any means other than through the interface that is provided by TestWin for use in accessing TestWin. Users agree not to use any automated means, including, without limitation, agents, robots, scripts, or spiders, to access, monitor, or copy any part of our sites, except those automated means that we have approved in advance and writing.
          </li>
          <li>The use of TestWin is subject to existing laws and legal processes. Nothing contained in these Terms and Conditions shall limit TestWin’s right to comply with governmental, court, and law-enforcement requests or requirements relating to Users' use of TestWin.
          </li>
          <li>Users may reach out to TestWin through -
            <ol>
              <li>Help support@testwin.in if the user has any concerns about a match and/or challenge within Forty-Eight (48) hours of winner declaration for the concerned challenge.
              </li>
              <li>Persons below the age of eighteen (18) years are not allowed to participate in any of the paid challenges, or tests (by whatever name called) on the TestWin Platform. The Users will have to disclose their real age at the time of getting access to the TestWin Platform.
              </li>
              <li>TestWin may not be held responsible for any content contributed by Users on the TestWin.
              </li>
            </ol>
          </li>
        </ul>
        <h3>7. Conditions of Participation</h3>
        <p>By entering a Challenge, the user agrees to be bound by these Terms and the decisions of TestWin. Subject to the terms and conditions stipulated herein below, the Company, at its sole discretion, may disqualify any user from a Challenge/test, refuse to award benefits or coins, and require the return of any rupee/coins, if the user engages in unfair conduct, which the Company deems to be improper, unfair or otherwise adverse to the operation of the Challenge/Test or is in any way detrimental to other Users which includes, but is not limited to:
          <ul>
            <li>Falsifying one's personal information (including, but not limited to, name, email address, bank account details, and/or any other information or documentation as may be requested by TestWin to enter a challenge and/or claim a coin/cash winning.;
            </li>
            <li>Engaging in any type of financial fraud or misrepresentation including unauthorized use of credit/debit instruments, payment wallet accounts, etc. to enter a Challenge or claim a cash/coin. It is expressly clarified that the onus to prove otherwise shall solely lie on the user.;
            </li>
            <li>Colluding with any other user(s) or engaging in any type of syndicate play;
            </li>
            <li>Any violation of Challenge rules or the Terms of Use;
            </li>
            <li>Accumulating points or coins through unauthorized methods such as automated bots, or other automated means;
            </li>
            <li>Using automated means (including but not limited to harvesting bots, robots, parsers, spiders, or screen scrapers) to obtain, collect or access any information on the Website or of any User for any purpose
            </li>
            <li>Any type of Cash Bonus misuse, misuse of the Invite Friends program, or misuse of any other offers or promotions;
            </li>
            <li>Tampering with the administration of a Test/Challenge or trying to in any way tamper with the computer programs or any security measure associated with a Test/Challenge;
            </li>
            <li>Obtaining other users’ information without their express consent and/or knowledge and/or spamming other users (Spamming may include but shall not be limited to sending unsolicited emails to users, sending bulk emails to TestWin Users, sending unwarranted email content either to selected Users or in bulk); or
            </li>
            <li>Abusing the Website in any way (‘unparliamentary language, slangs or disrespectful words’ are some of the examples of Abuse)
            </li>
          </ul>
        </p>
        <h3>8.</h3><p>It is clarified that in case a User is found to violate this policy, TestWin reserves its right to initiate appropriate Civil/Criminal remedies as it may be advised other than forfeiture and/or recovery of coins if any.
        </p>
        <h3>9. Registration for a challenge</h3>
        <ul>
          <li>To register for the Challenge(s), Participants are required to accurately provide the following information:
            <ol>
              <li>Full Name
              </li>
              <li>Team Name(s)
              </li>
              <li>E-mail address</li>
              <li>Password</li>
              <li>State of Residence</li>
              <li>Gender</li>
              <li>Date of birth</li>
              <li>Phone Number</li>
            </ol>
          </li>
          <li>Participants are also required to confirm that they have read and shall abide by these Terms and Conditions and Privacy Policy.
          </li>
          <li>Login/Signup to the platform indicates that they have read the Terms and Conditions and Privacy Policy and are consenting to the same.
          </li>
          <li>In the event a Participant indicates, while entering an address, that he/she is a resident of either Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim, or Telangana such Participant will not be permitted to proceed to sign up for any match in the paid version of the Test/Challenge as described below.
          </li>
          <li>Once the Participants have entered the above information and clicked on the "register" tab, and such Participants are above the age of 18 years, they are sent an email confirming their registration and containing their login information.
          </li>
        </ul>
        <h3>10. Challenge(s)/Test(s) ,Participation, and Coins)</h3>
        <ul>
          <li>As part of its services, TestWin may make available the challenge(s)/Test(s) on the TestWin platform.
          </li>
          <li>Currently, the following challenges are made available on the TestWin platform: Challenge/Test list and describe how a challenge/Test is created and how it is assigned to others and how to invite others to take the challenge.</li>
          <li>Participants shall pay a pre-designated amount for participating in the challenge(s)/ Test(s) being created on the TestWin platform.
            In the event a Participant indicates, while entering an address, that he/she is a resident of either Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim, or Telangana such Participant will not be permitted to proceed to sign up for the match or challenge and may not participate in any paid version of the Challenge(s) /Test(s).
          </li>
          <li>In two members and above public Test/challenges, where all participants have entered the challenge/Test, then the winner may be awarded the total pooled amount collected from the participants minus the platform charges. . In the event a user is found to be violating this policy, the Company reserves the right to initiate appropriate action against such users as it deems fit, which shall also include forfeiture of the Cash/Coins Amount.
          </li>
          <li>In Starting Lineup Feature: The purpose of indicating a Learner/challenger inclusion in the final participation lineup is only to provide information and assist a user to know the users who will be participating on TestWin.. TestWin, shall not take any liability if a participant earlier indicated as 'Participating' ' does not play or start for any reason whatsoever.
          </li>
        </ul>
        <h3>11. The legality of Game of Skill</h3>
        <ul>
          <li>Games of skill are legal, as they are excluded from the ambit of Indian gambling legislations including, the Public Gambling Act of 1867. The Indian Supreme Court in the cases of State of Andhra Pradesh v. K Satyanarayana (AIR 1968 SC 825) and KR Lakshmanan v. State of Tamil Nadu (AIR 1996 SC 1153) has held that a game in which success depends predominantly upon the superior knowledge, training, attention, experience, and adroitness of the player shall be classified as a game of skill.
          </li>
          <li>The Challenge(s)/Test(s) described above (across the TestWin Services) are games of skill and knowledge as the success of Participants depends primarily on their superior knowledge of the Subject matter in which they are participating. </li>
          <li>By participating in this Challenge(s)/Test(s), each Participant acknowledges and agrees that he/she is participating in a game of skill or knowledge.
          </li>
        </ul>
        <h3>12. Eligibility</h3>
        <ul>
          <li>The Challenges(s)/Tets(s) are open only to persons above the age of 18 years.</li>
          <li>The Challenge(s)/Test(s) are open only to persons currently residing in India.
          </li>
          <li>TestWin may, following the laws prevailing in certain Indian states, bar individuals residing in those states from participating in the Contest(s). Currently, individuals residing in the Indian states of Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim, or Telangana may not participate in the paid version of the Contest as the laws of these states are unclear/ bar persons from participating in games of skill where participants are required to pay to enter.
          </li>
          <li>Persons who wish to participate must have a valid email address and Paytm-linked mobile number.
          </li>
          <li>TestWin may on receipt of information bar a person from participation and/or withdrawing winning amounts if such person is found to be one deploying cheating tactics on the platform.
          </li>
          <li>Only those Participants who have successfully registered on the TestWin as well as registered before each test under the procedure outlined above shall be eligible to participate in the Challenge/Test and win cash/coins.
          </li>
        </ul>
        <h3>13. Terms of Wallet coins withdrawal</h3>
        <p>Your wallet cash refers to the cash a user has in TestWin’s wallet, distinct and private to a user. The users receive the cash in their TestWin’s wallet if they win cash in Testwin’s challenges/tests, earn a referral bonus, add any coins from a verified payment method, get cash from TestWin’s scratch card or cashback offers, and through any further scope that the company may introduce on a later stage.
        </p>
        <p>The users are allowed to withdraw cash from their wallets, at their discretion, only in the following case:
          <ul>
            <li>If the cash is a part of the cash rewards won in TestWin’s challenges. If the cash is earned from the referral bonus when a user refers to one's friends, then, in that case, the user is not eligible to withdraw such cash.</li>
            <li>If the users try to withdraw the amount which they have added to their TestWin wallet externally, from the verified and available payment methods then they cannot withdraw the same.
            </li>
            <li>If the cash is earned from the scratch cards or cashback from TestWin.</li>
          </ul>
          However, in the above cases, the user may use such cash to pay the entry fees, create a test and join a new challenge/test hosted on Testwin’s platform.
        </p>
        <h3>14. Promotions</h3>
        <p>TestWin may, from time to time, include contests, promotions, sweepstakes, or other activities (“Promotions”) that require you to submit material or information concerning yourself. Please note that all Promotions may be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age and geographic location. You are responsible to read all Promotions rules to determine whether or not you are eligible to participate. If you enter any Promotion, you agree to abide by and comply with all Promotions Rules.
        </p>
        <p>Additional terms and conditions may apply to services, which terms and conditions are made a part of this Agreement by this reference.
        </p>
        <h3>15. General Conditions</h3>
        <p>If it comes to the notice of TestWin that any governmental, statutory, or regulatory compliances or approvals are required for conducting any Challenge(s)/Test(s) or if it comes to the notice of TestWin that conduct of any such Challenge(s)/Test(s) is prohibited, then TestWin shall withdraw and/or cancel such Challenge(s)/Test(s) without prior notice to any Participants or winners of any Challenge(s)/Test(s). Users agree not to make any claim in respect of such cancellation or withdrawal of the Challenge or Test in any manner.
        </p>
        <p>Employees, directors, affiliates, relatives, and family members of TestWin, will not be eligible to participate in any Challenge(s)/Tets(s)
        </p>
        <h3>16. Dispute and Dispute Resolution</h3>
        <ul>
          <li>The courts of competent jurisdiction in Mumbai shall have exclusive jurisdiction to determine any disputes arising out of, or in connection with, the TestWin Services provided by TestWin (including the Challenge(s)/Test(s)), the construction, validity, interpretation, and enforceability of these Terms and Conditions, or the rights and obligations of the User(s) (including Participants) or TestWin, as well as the exclusive jurisdiction to grant interim or preliminary relief in case of any dispute referred to arbitration as given below. All such issues and questions shall be governed and construed per the laws of the Republic of India.
          </li>
          <li>In the event of any legal dispute (which may be a legal issue or question) which may arise, the party raising the dispute shall provide a written notification ("Notification") to the other party. Upon receipt of notification, the parties shall first try to resolve the dispute through discussions. If the parties are unable to resolve the dispute within fifteen (15) days of receipt of Notification, the dispute shall be settled by arbitration.
          </li>
          <li>The place of arbitration shall be Jaipur, India. All arbitration proceedings shall be conducted in English and by the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time.
          </li>
          <li>The arbitration award will be final and binding on the Parties, and each Party will bear its costs of arbitration and equally share the fees of the arbitrator unless the arbitral tribunal decides otherwise. The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned award.
          </li>
          <li>Nothing contained in these Terms and Conditions shall prevent TestWin from seeking and obtaining interim or permanent equitable or injunctive relief, or any other relief available to safeguard TestWin’s interest before, during, or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any court having jurisdiction to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on the part of TestWin to pursue any remedy for monetary damages through the arbitration described herein.
          </li>
        </ul>
        <h3>17. Release and Limitations of Liability</h3>
        <ul>
          <li>Users shall access the TestWin Services provided on TestWin voluntarily and at their own risk. TestWin shall, under no circumstances be held responsible or liable on account of any loss or damage sustained (including but not limited to any accident, injury, death, or loss of property) by Users or any other person or entity during access to the TestWin Services (including participation in the Challenge(s)) or as a result of acceptance of any coins.
          </li>
          <li>By entering the challenges and accessing the TestWin Services provided therein, Users hereby release the form and agree to indemnify TestWin, and/ or any of its directors, employees, partners, associates, and licensors, from and against all liability, cost, loss, or expense arising out their access to the TestWin Services including (but not limited to) personal injury and damage to property and whether direct, indirect, consequential, foreseeable, due to some negligent act or omission on their part, or otherwise</li>
          <li>TestWin accepts no liability, whether jointly or severally, for any errors or omissions, whether on behalf of itself or third parties concerning the coins..
          </li>
          <li>Users shall be solely responsible for any consequences which may arise due to their access to TestWin Services by conducting an illegal act or due to non-conformity with these Terms and Conditions and other rules and regulations concerning TestWin Services, including the provision of incorrect address or other personal details. Users also undertake to indemnify TestWin and their respective officers, directors, employees, and agents in the happening of such an event (including without limitation cost of an attorney, legal charges, etc.) on a full indemnity basis for any loss/damage suffered by TestWin on account of such activity on the part of the Users.
          </li>
          <li>Users shall indemnify, defend, and hold TestWin harmless from any third party/entity/organization claims arising from or related to such User's engagement with the TestWin or participation in any Challenge. In no event shall TestWin be liable to any User for acts or omissions arising out of or related to User's engagement with the TestWin or his/her participation in any Challenge(s)/Test(s)
          </li>
          <li>In consideration of TestWin allowing Users to access the TestWin Services, to the maximum extent permitted by law, the Users waive and release every right or claim, all actions, causes of actions (present or future) each of them has or may have against TestWin, its respective agents, directors, officers, business associates, group companies, sponsors, employees, or representatives for all and any injuries, accidents, or mishaps (whether known or unknown) or (whether anticipated or unanticipated) arising out of the provision of TestWin Services or related to the Challenges or the coins of the Challenges.
          </li>
        </ul>
        <h3>18. Disclaimers</h3>
        <ul>
          <li>To the extent permitted under law, neither TestWin nor its parent/holding company, subsidiaries, affiliates, directors, officers, professional advisors, or employees shall be responsible for the deletion, the failure to store, the misdelivery, or the untimely delivery of any information or material.
          </li>
          <li>To the extent permitted under law, TestWin shall not be responsible for any harm resulting from downloading or accessing any information or material, the quality of servers, games, products, and coins. TestWin disclaims any responsibility, and if a user pays for access to one of TestWin’s Services the User will not be entitled to a refund as a result of, any inaccessibility that is caused by TestWin’s maintenance on the servers or the technology that underlies our sites, failures of TestWin’s service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war, civil disturbance, or any other cause beyond our reasonable control. In addition, TestWin does not provide any warranty as to the content on the TestWin(s). TestWin(s) content is distributed on an "as is, as available" basis.
          </li>
          <li>Any material accessed, downloaded, or otherwise obtained through TestWin is done at the User's discretion, competence, acceptance, and risk, and the User will be solely responsible for any potential damage to the User's computer system or loss of data that results from a User's download of any such material.
          </li>
          <li>TestWin shall make its best endeavors to ensure that the TestWin (s) is error-free and secure, however, neither TestWin nor any of its partners, licensors or associates makes any warranty that:
            <ol>
              <li>the TestWin(s) will meet Users' requirements,
              </li>
              <li>TestWin (s) will be uninterrupted, timely, secure, or error-free
              </li>
              <li>the results that may be obtained from the use of TestWin(s) will be accurate or reliable; and
              </li>
              <li>the quality of any products, TestWin Services, information, or other material that Users purchase or obtain through TestWincom(s) will meet Users' expectations.</li>
            </ol>
          </li>
          <li>In case TestWin discovers any error, including any error in the determination of Winners or in the transfer of amounts to a User's account, TestWin reserves the right (exercisable at its discretion) to rectify the error in such manner as it deems fit, including through set-off of the erroneous payment from amounts due to the User or deduction from the User's account of the amount of erroneous payment. In case of exercise of remedies per this clause, TestWin agrees to notify the User of the error and of the exercise of the remedy(ies) to rectify the same.
          </li>
          <li>To the extent permitted under law, neither TestWin nor its partners, licensors, or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use our sites, even if we have been advised of the possibility of such damages.
          </li>
          <li>Any TestWin Services, events, or Challenge(s) being hosted or provided, or intended to be hosted on the TestWin platform and requiring specific permission or authority from any statutory authority or any state or the central government, or the board of directors shall be deemed canceled or terminated if such permission or authority is either not obtained or denied either before or after the availability of the relevant TestWin Services, events or Challenge(s) are hosted or provided.
          </li>
          <li>To the extent permitted under law, in the event of suspension or closure of any Services, events, or Tests/Challenges, Users (including Participants) shall not be entitled to make any demands, or claims, of any nature whatsoever.
          </li>
        </ul>
        <h3>19. Miscellaneous</h3>
        <ul>
          <li>TestWin may be required under certain legislations, to notify User(s) of certain events. User(s) hereby acknowledge and consent that such notices will be effective upon TestWin posting them on the TestWin or delivering them to the User through the email address provided by the User at the time of registration. User(s) may update their email address by logging into their account on the TestWin. If they do not provide TestWin with accurate information, TestWin cannot be held liable for failure to notify the User.
          </li>
          <li>TestWin shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond TestWin's control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials or any cancellation of any cricket/football/ basketball/kabaddi/volleyball/ hockey/baseball/handball/American football match to which a Challenge relates. In such circumstances, TestWin shall also be entitled to cancel any related Challenge(s) and to process an appropriate refund for all Participants.
          </li>
          <li>TestWin's failure to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.</li>
          <li>Users agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to the use of the TestWin or these Terms must be filed within thirty (30) days of such claim or cause of action arising or be forever barred.
          </li>
          <li>These Terms and Conditions, including all terms, conditions, and policies that are incorporated herein by reference, constitute the entire agreement between the User(s) and Wolooka Technologies Private Limited and govern your use of the TestWin, superseding any prior agreements that any User may have with Wolooka Technologies Private Limited.
          </li>
          <li>If any part of these Terms and Conditions is determined to be indefinite, invalid, or otherwise unenforceable, the rest of these Terms and Conditions shall continue in full force.</li>
          <li>TestWin reserves the right to moderate, restrict or ban the use of the TestWin, specifically to any User, or generally, by TestWin are the policy/policies from time to time, at its sole and absolute discretion and without any notice.
          </li>
          <li>TestWin may, at its sole and absolute discretion, permanently close or temporarily suspend any TestWin Services (including any Challenge(s)).
          </li>
          <li>TestWin may from time to time conduct/organize promotions/offers on the platform. Any two or more promotions cannot be clubbed together with any other promotions that are running simultaneously on the TestWin platform. Also, promotions/offers cannot be clubbed with Cash Bonus available to any user.
          </li>
        </ul>
        <h3>20. Grievance Redressal Mechanism</h3>
        <ul>
          <li>In case you have any complaints or grievances of (i) any User Content that you believe violates these Terms (other than an infringement of Intellectual Property Rights) for the reporting mechanism for such claims), (ii) your access to the Platform or (iii) any User Content which you believe is, prima facie, any material which is obscene, defamatory towards the complainant or any person on whose behalf you are making the complaint, or is like impersonation in an electronic form, including artificially morphed images of such individual, please share the same with us by writing to support@testwin.in In your complaint or grievance, please include the following information:
            <ol>
              <li>Your name and contact details: name, address, contact number, and email address;
              </li>
              <li>Your relation to the subject matter of the complaint, i.e. complainant or person acting on behalf of an affected person;
              </li>
              <li>The name and age of the person aggrieved or affected by the subject matter of the complaint, in case you are acting on behalf of such person, and a statement, under penalty of perjury, that you are authorized to act on behalf of such person and to provide such person's personal information to us about the complaint/grievance;
              </li>
              <li>Description of the complaint or grievance with clear identification of the User Content about which such complaint or grievance is made;
              </li>
              <li>A statement that you believe, in good faith, that the User Content violates these Terms
              </li>
              <li>A statement that the information provided in the complaint or grievance is accurate.
              </li>
            </ol>
          </li>
          <li>The Company respects the Intellectual Property Rights of others. All names, logos, marks, labels, trademarks, copyrights, or intellectual and proprietary rights on the Platform belonging to any person (including User), entity, or third party are recognized as proprietary to the respective owners. You are requested to send us a written notice/ intimation if you notice any act of infringement on the Platform, which must include the following information:
            <ol>
              <li>Clear identification of the copyrighted work allegedly infringed;
              </li>
              <li>Clear identification of the allegedly infringing material on the Platform;
              </li>
              <li>Your contact details: name, address, e-mail address, and phone number;
              </li>
              <li>A statement that you believe, in good faith, that the use of the copyrighted material allegedly infringed on the Platform is not authorized by your agent or the law;
              </li>
              <li>A statement that the information provided in the notice is accurate, and under penalty of perjury, that the signatory is authorized to act on behalf of the owner of an exclusive copyright right that is allegedly infringed;
              </li>
              <li>Your signature or the signature of your authorized agent.
              </li>
            </ol>
          </li>
          <li>The aforesaid notices can be sent to the Company by email at support@testwin.in
          </li>
          <li>On receiving the such complaint, grievance, or notice, the Company reserves the right to investigate and/or take such action as the Company may deem appropriate. The Company may reach out to you to seek further clarification or assistance with the investigation, or verify the statements made in your complaint, grievance, or notice, and you acknowledge that your timely assistance with the investigation would facilitate the redressal of the same.
          </li>
          <li>The name and title of the Grievance Redressal Officer are as follows:<br />
            NAME- Ramleen Kaur<br />
            Email- support@testwin.in<br />
            Address- 3rd Floor Bhamashah Technology Hub Jaipur<br /><br />

            The Grievance Officer identified above under the provisions of applicable laws including but not limited to the Information Technology Act, 2000 and the Consumer Protection Act, 2019, and the Rules enacted under those laws.
            The Company reserves the right to replace the Grievance Redressal Officer at its discretion through the publication of the name and title of such replacement on the website, which replacement shall come into effect immediately upon publication.</li>
        </ul>
        <h3>21. Market Model Policy</h3>
        <p>We reserve the right, but have no obligation, to monitor the materials posted on the Platform. TestWin shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms and Conditions. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES. Please be advised that such Content posted does not necessarily reflect TestWin views. In no event shall TestWin assume or have any responsibility or liability for any Content posted or for any claims, damages, or losses resulting from the use of Content and/or appearance of Content on the Platform. You hereby represent and warrant that you have all necessary rights in and to all Content which you provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libelous, tortious, or otherwise unlawful information.
          <ol>
            <li>Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Platform, including payment and delivery of related products or services, and any other terms, conditions, warranties, or representations associated with such dealings, are solely between you and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Platform.
            </li>
            <li>It is possible that other users (including unauthorized users or "hackers") may post or transmit offensive or obscene materials on the Platform and that you may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use of the Platform, and the recipient may use such information to harass or injure you. We do not approve of such unauthorized uses, but by using the Platform you acknowledge and agree that We are not responsible for the use of any personal information that you publicly disclose or share with others on the Platform. Please carefully select the type of information that you publicly disclose or share with others on the Platform.
            </li>
            <li>TestWin shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your own or through a group(s) of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services).
            </li>
            <li>If you choose to browse or transact on the Platform using the voice-command-based shopping
              feature, you acknowledge that TestWin and/or its affiliates will collect, store and use your voice inputs on this feature to customize your experience and provide additional functionality as well as to improve TestWin’s and/or its affiliates’ products and services. TestWin’s and/or its affiliate's use of your voice data will be by the TestWin Privacy Policy. You accept that your use of this feature is restricted to the territory of the Republic of India. This feature may not be accessible on certain devices and requires an internet connection. TestWin reserves the right to change, enhance, suspend, or discontinue this feature, or any part of it, at any time without notice to you. Your continued use of this feature constitutes your acceptance of the terms related to this feature.
            </li>
            <li>You acknowledge and agree that the Hindi, Telugu, Tamil, Kannada and Marathi, Bengali, Gujarati, Odia, Malayalam, Punjabi & Assamese translation features are made available on the Platform on a reasonable-effort basis for convenience only, without any representation or warranties by TestWin, express or implied, including the translations being error-free, their accuracy, completeness or reliability. Under the translation feature, you will have the option to add delivery addresses in the language selected by you out of the featured languages. Such delivery addresses shall be transliterated into the English language for processing, handling, and fulfilling your orders on the Platform. TestWin expressly disclaims any liability of any nature whatsoever arising from or related to the said translation/transliteration features on the Platform. Some features and sections on the Platform may not be translated into the language selected by you [Hindi, Telugu, Tamil, Kannada, Marathi, Bengali, Gujarati, Odia, Malayalam, Punjabi & Assamese language, as applicable], and the English version of the same will be the definitive version. In case of any conflict or inconsistency between the translated terms and the English version available on the Platform, the English version on the Platform shall prevail. This feature may not be accessible on certain devices. TestWin reserves the right to change, enhance, suspend, or discontinue this feature, or any part of it, at any time without notice to you.
            </li>
          </ol>
          All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, and artwork (collectively, "Content"), is third-party user-generated content and TestWin has no control over such third-party user-generated content as TestWin is merely an intermediary for this Terms of Use.<br /><br />
          Except as expressly provided in these Terms of Use, no part of the Platform and no Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, or distributed in any way (including "mirroring") to any other computer, server, Platform or another medium for publication or distribution or any commercial enterprise, without TestWin's express prior written consent.<br /><br />
          You may use the information on the products and services purposely made available on the Platform for downloading, provided that you.
        </p>
        <ol>
          <li> do not remove any proprietary notice language in all copies of such documents,
          </li>
          <li> use such information only for your personal, non-commercial informational purpose and do not copy or post such informtion on any networked computer or broadcast it in any media,
          </li>
          <li>make no modifications to any such information, and
          </li>
          <li>do not make any additional representations or warranties relating to such documents.
          </li>
        </ol>
        <p>You shall be responsible for any notes, messages, emails, reviews, ratings, billboard postings, photos, drawings, profiles, opinions, ideas, images, videos, audio files, or other materials or information posted or transmitted to the Platform (collectively, "Content"). Such Content will become Our property and you grant Us the worldwide, perpetual, and transferable rights to such Content. We shall be entitled to, consistent with Our Privacy Policy as adopted by applicable law, use the Content or any of its elements for any type of use forever, including but not limited to promotional and advertising purposes and in any media, whether now known or hereafter devised, including the creation of derivative works that may include the Content you provide. You agree that any Content you post may be used by us, consistent with Our Privacy Policy and Rules of Conduct on Site as mentioned herein, and you are not entitled to any payment or other compensation for such use.<br /><br />
          SOME CONTENT OFFERED ON THE PLATFORM MAY NOT BE SUITABLE FOR SOME VIEWERS AND THEREFORE VIEWER DISCRETION IS ADVISED. ALSO, SOME CONTENT OFFERED ON THE PLATFORM MAY NOT BE APPROPRIATE FOR VIEWERSHIP BY CHILDREN. PARENTS AND/OR LEGAL GUARDIANS ARE ADVISED TO EXERCISE DISCRETION BEFORE ALLOWING THEIR CHILDREN AND/OR WARDS TO ACCESS CONTENT ON THE PLATFORM.
        </p>
      </div>
    </div>
  )
}

export default TermsAndConditions