import React from 'react';
import '../css/leaderboard.css';
import leaderboard from '../pictures/leaderboard.svg'
import leaderboard1 from '../pictures/leaderboard1.png'
import leaderboard2 from '../pictures/leaderboard2.png'
import leaderboard3 from '../pictures/leaderboard3.png'

function Leaderboard() {
    return (
        <div className='leaderboard d-flex flex-wrap justify-content-around align-items-center' style={{padding:"50px 100px 50px 100px",backgroundImage: `url(${leaderboard})`, backgroundRepeat: "no-repeat" }}>
            <div style={{}}>
                <p style={{ fontSize: "40px", fontWeight: "600" }}>Leaderboard <br />of this week</p>
                <p style={{ fontSize: "18px", color: "#959595" }}>Here are top challengers from <br />past week</p>
            </div>
            <div>
                <div className='leaderboardContainers d-flex justify-content-between align-items-center'>
                    <div className='leaderboard1 p-3 m-2 d-flex flex-column align-items-center justify-content-between' style={{ background: "#F7F5FB", borderRadius: "99.5px 99.5px 0px 0px" }}>
                        <img src={leaderboard1} />
                        <p style={{fontSize:"12px",fontWeight:"300",color:"#201D1D"}}>Congrats</p>
                        <p style={{fontWeight:"500",fontSize:"18px"}}>"Garima"</p>
                        <p style={{fontSize:"12px",fontWeight:"300",color:"#201D1D"}}>for winning</p>
                        <p className='rupees' style={{fontSize:"28px",fontWeight:"600"}}>₹ 720</p>
                        <a href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US"><button style={{background:"#7F01FC",color:"white",borderRadius:"5px",border:"none",padding:"3px 20px"}}>Challenge Her</button></a>
                    </div>
                    <div className='leaderboard2 p-3 m-2 d-flex flex-column align-items-center justify-content-between' style={{ background: "#F7F5FB", borderRadius: "99.5px 99.5px 0px 0px",position:"relative",bottom:"80px"}}>
                        <img src={leaderboard2} />
                        <p style={{fontSize:"12px",fontWeight:"300",color:"#201D1D"}}>Congrats</p>
                        <p style={{fontWeight:"500",fontSize:"18px"}}>"Ripu"</p>
                        <p style={{fontSize:"12px",fontWeight:"300",color:"#201D1D"}}>for winning</p>
                        <p className='rupees' style={{fontSize:"28px",fontWeight:"600"}}>₹ 1220</p>
                       <a href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US"> <button style={{background:"#7F01FC",color:"white",borderRadius:"5px",border:"none",padding:"3px 20px"}}>Challenge Him</button></a>
                    </div>
                    <div className='leaderboard3 p-3 m-2 d-flex flex-column align-items-center justify-content-between' style={{ background: "#F7F5FB", borderRadius: "99.5px 99.5px 0px 0px",position:"relative",bottom:"20px" }}>
                        <img src={leaderboard3} />
                        <p style={{fontSize:"12px",fontWeight:"300",color:"#201D1D"}}>Congrats</p>
                        <p style={{fontWeight:"500",fontSize:"18px"}}>"Ankit"</p>
                        <p style={{fontSize:"12px",fontWeight:"300",color:"#201D1D"}}>for winning</p>
                        <p className='rupees' style={{fontSize:"28px",fontWeight:"600"}}>₹ 980</p>
                       <a href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US"> <button style={{background:"#7F01FC",color:"white",borderRadius:"5px",border:"none",padding:"3px 20px"}}>Challenge Him</button></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Leaderboard