import React from 'react';
import '../css/performanceAnalysis.css';
import performanceanalysis from '../pictures/performanceanalysis.png'
import performanceanalysisbackground from '../pictures/performanceanalysisbackground.svg'
import star1 from '../pictures/star1.svg';
import star2 from '../pictures/star2.svg';

function PerformanceAnalysis() {
  return (
    <>
    <div className='performanceWrapper' style={{margin:"50px",borderRadius:"22px",backgroundColor:"#F7F5FB" ,backgroundImage:`url(${performanceanalysisbackground})`,backgroundSize:"cover"}}>
       <div className='d-flex flex-wrap justify-content-around align-items-center' style={{margin:"0px 70px"}}>
        <div>
           <img className='performanceMockup' src={performanceanalysis}/>
        </div>
        <div>
           <div style={{fontSize:"40px",lineHeight:"150%",fontWeight:"600",marginTop:"20px"}}><img src={star1}/>  A.I Based <br/>Performance Analysis</div>
           <div style={{color:"#959595" , marginTop:"15px"}}>You can check your Individual & Comparative<br/> analysis to understand your learning, so you<br/> can learn FASTER  <img src={star2}/></div>
        </div>
        </div>
    </div>
    </>
  )
}

export default PerformanceAnalysis