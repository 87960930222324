import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../css/aspirant.css';
import aspirant1 from '../pictures/aspirant1.jpg';
import aspirant2 from '../pictures/aspirant2.jpg';
import aspirant3 from '../pictures/aspirant3.jpg';
import aspirant4 from '../pictures/aspirant4.jpg';

function Aspirant() {
  return (
    <Carousel 
    indicators = {false}
     controls={false}
     >
      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={aspirant1}
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={aspirant2}
          alt="Second slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={aspirant3}
          alt="Third slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={aspirant4}
          alt="Third slide"
        />
        
      </Carousel.Item>
    </Carousel>
  );
}

export default Aspirant;