
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeroSection from './components/HeroSection';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Contact from './pages/component/Contact';
import AboutUs from './pages/component/AboutUs';
import PrivacyPolicy from './pages/component/PrivacyPolicy';
import TermsAndConditions from './pages/component/TermsAndConditions';

function App() {
  return (
    <>

      <BrowserRouter>

        <Navbar />
        <Routes>
          <Route path="/" element={<HeroSection />} />
          {/* <Route index element={<HeroSection/>} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/terms&conditions" element={<TermsAndConditions />} />
          <Route path="/privacypolicy" element={< PrivacyPolicy/>} />
        </Routes>
        <Footer />
      </BrowserRouter>

    </>
  );
}

export default App;
