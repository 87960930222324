import React from 'react'

function PopularExams() {
  return (
    <>
    <div style={{background:"#F7F5FB"}}>
    <div className='d-flex flex-wrap align-items-center justify-content-between mt-5 p-3' style={{padding:"0px 80px"}} >
      <div style={{fontSize:"16px" , fontWeight:"600"}}>Popular Exams :</div>
      <div style={{color:"#7F01FC"}}>NEET UG </div>
      <div style={{color:"#7F01FC"}}> IIT JEE </div>
      <div style={{color:"#7F01FC"}}>  Bank Exams </div>
      <div style={{color:"#7F01FC"}}>  SSC </div>
      <div style={{color:"#7F01FC"}}> UPSC CSE-GS </div>
      <div style={{color:"#7F01FC"}}> Railways </div>
      <div style={{color:"#7F01FC"}}> UPSC CSE-GS </div>
      <div style={{color:"#7F01FC"}}> CAT & other MBA entrance tests</div>
    </div>
    </div>
    </>
  )
}

export default PopularExams