import React from 'react';
import '../css/footer.css';
import {Link} from 'react-router-dom';
import logo from '../pictures/logo.svg';
import socialmediaf1 from '../pictures/socialmediaf1.svg'
import socialmediaf2 from '../pictures/socialmediaf2.svg'
import socialmediaf3 from '../pictures/socialmediaf3.svg'
import socialmediaf4 from '../pictures/socialmediaf4.svg'
import Line from '../pictures/Line.svg'

function Footer() {
  return (
    <div style={{background:"#7F01FC"}}>
      <div className='d-flex flex-wrap justify-content-around align-items-center' style={{padding:"50px"}} >
        <div className='footer'>
          <img src={logo} classNameName="img-fluid" alt="..." style={{marginLeft:"35px"}} /><br />
          {/* <a href="index.html" className="logo d-flex align-items-center"> */}
          <div style={{color:"white",marginTop:"10px"}}>
            <span style={{
              fontWeight: "700",
              fontSize: "35px",
              lineHeight: "154%"
            }}>TESTWIN</span>
            <p style={{
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "154%",
              color:"white"
            }}>LEARN • PLAY • EARN</p>
            <div className="copyright mt-5" >
              &copy; 2023 Testwin Co., All right reserved.
            </div>
          </div>

        </div>

        <div className="">
          <h4 style={{color:"#F6F6F6"}}>SITEMAPS:-</h4>
          <ul style={{listStyleType:"none"}}>
            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} to="/aboutus">About</Link></li>
            <li><a style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} href="#">FAQ’s</a></li>
            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} to="/contact">Career </Link></li>
            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} to="/privacypolicy">Privacy Policy</Link></li>
            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} to="terms&conditions">Terms & conditions</Link></li>
          </ul>
        </div>
           <img src={Line}/>
        <div className="">
          <h4 style={{color:"#F6F6F6"}}>PARTNERS:-</h4>
          <ul style={{listStyleType:"none"}}>
            <li><a style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} href="#">Farmkal</a></li>
            <li><a style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} href="#">UPXO</a></li>
            <li><a style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} href="https://www.lexrn.in">Lexrn</a></li>
            <li><a style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} href="#"></a></li>
            
            </ul>
            </div>

            <div className='mt-2'>
            <p style={{ fontSize: "14px", fontWeight: "700",color:"white"}}>
              Bhamashah techno hub (BTH) <br />
              3rd Floor Sansthan Path,<br />
              Jhalana Gram, Malviya Nagar,<br />
              Jaipur , Rajasthan<br />
              302017<br /><br />
            </p>
            <p style={{color:"white"}}>Supoort@Testwin.in</p>
            <img className="m-1" src={socialmediaf1}/>
            <img className="m-1" src={socialmediaf2}/>
            <a href="https://www.instagram.com/testwin.play?igshid=MjljNjAzYmU="><img className="m-1" src={socialmediaf3}/></a>
            <a href="https://www.linkedin.com/company/testwin/"><img className="m-1" src={socialmediaf4}/></a>
            </div>
        
      </div>
    </div>
  )
}

export default Footer