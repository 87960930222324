import React from 'react';
import contact1 from '../images/contact1.svg';
import contact2 from '../images/contact2.svg';
import contact3 from '../images/contact3.svg';
import contact4 from '../images/contact4.svg';
import contact5 from '../images/contact5.svg';

function Contact() {
  return (
    <div style={{ marginTop: "85px" }}>
      <div className='mt-2 d-flex flex-column align-items-center justify-content-center' style={{ background: "#7F01FC" }}>
        <p style={{ fontSize: "30px", fontWeight: "700", color: "#FFFFFF" }}>Contact Us</p>
        <p style={{ fontSize: "22px", fontWeight: "400", color: "#FFFFFF" }}>Got something you want to talk about? Email us and we will get back to you as soon as we can.</p>
      </div>


      <div className='d-flex flex-wrap align-items-center justify-content-around' style={{ marginTop: "100px" }}>
        <div>

          <div className='d-flex '>
            <img style={{ marginBottom: "85px", marginRight: "15px" }} src={contact1} />
            <div>
              <p style={{ fontSize: "18px", fontWeight: "500", marginBottom: "4px" }}> General or App related issues</p>
              <p style={{ fontSize: "16px" }}>For feedback or issues related to the App: <br />
                <span> <a style={{ textDecoration: "none" }} href="mailto:help@testwin.co">support@Testwin.co</a> </span> </p>
            </div>
          </div>
          <div className='d-flex '>
            <img style={{ marginBottom: "85px", marginRight: "15px" }} src={contact2} />
            <div>
              <p style={{ fontSize: "18px", fontWeight: "500", marginBottom: "4px" }}>Career</p>
              <p style={{ fontSize: "16px" }}>Send Resume on: <br />
                <span> <a style={{ textDecoration: "none" }} href="mailto:hr@testwin.co">hr@Testwin.co</a> </span> </p>
            </div>
          </div>
          <div className='d-flex '>
            <img style={{ marginBottom: "85px", marginRight: "15px" }} src={contact3} />
            <div>
              <p style={{ fontSize: "18px", fontWeight: "500", marginBottom: "4px" }}>Phone</p>
              <p style={{ fontSize: "16px" }}>Mon-Sat from 9am to 6pm.<br />
                +91 91166 33386</p>
            </div>
          </div>
          <div className='d-flex '>
            <img style={{ marginBottom: "130px", marginRight: "15px" }} src={contact4} />
            <div>
              <p style={{ fontSize: "18px", fontWeight: "500", marginBottom: "4px" }}>Office</p>
              <p style={{ fontSize: "16px" }}>Come to say Hello at our office.<br />
                <span style={{ fontWeight: "500" }}>Bhamashah Techno Hub (BTH)</span><br />
                3rd Floor Sansthan Path, Jhalana Gram,<br />
                Malviya Nagar, Jaipur, Rajasthan<br />
                PIN:302017
              </p>
            </div>
          </div>

        </div>

        <div>
          <img src={contact5} />
        </div>

      </div>
      <div className='mt-5 d-flex flex-column align-items-center justify-content-center'>
        <h3>Contact Us</h3>
        <form action="https://formsubmit.co/support@testwin.in" method="POST">
          <div className="row" style={{ margin: "40px 0px" }}>

            <div className="col-5">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">First Name</label>
                <input type="text" name="name" required class="form-control" placeholder="First Name" />
              </div>
            </div>
            <div className="col-5">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Last Name</label>
                <input type="text" class="form-control" placeholder="Last Name" />
              </div>
            </div>

            <div className="col-10">
              <label for="exampleFormControlInput1" class="form-label">Email address</label>
              <input type="email" name="email" required class="form-control" placeholder="Email address" />
              <input type="hidden" name="_captcha" value="false"></input>
              <input type="hidden" name="_next" value="https://testwin.co/contact"></input>
            </div>
            <div className="col-10">
              <label for="exampleFormControlInput1" class="form-label">Your message</label>
              <textarea name="message" required class="form-control" rows="5" placeholder="Textarea" />
            </div>
            <div>
              <button type="submit" className='col-10 fw-bold mt-2' style={{ color: "white", background: "#7F01FC", border: "none", padding: "10px 20px", borderRadius: "10px" }}>Send Message</button>

            </div>
          </div>
        </form>

      </div>
    </div>
  )
}

export default Contact