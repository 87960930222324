import React from 'react';
import '../css/playTestSteps.css';
import Carousel from 'react-bootstrap/Carousel';
import playteststeps1 from '../pictures/playteststeps1.png';
import playteststeps2 from '../pictures/playteststeps2.png';
import playteststeps3 from '../pictures/playteststeps3.png';
import playteststeps4 from '../pictures/playteststeps4.png';
import playteststeps5 from '../pictures/playteststeps5.png';
import clouds from '../pictures/clouds.svg';
import design from '../pictures/design.svg';
import design2 from '../pictures/design2.svg';

function PlayTestSteps() {
    return (
        <>
            <Carousel variant="dark" slide={false}>
                <Carousel.Item>
                    <div className='d-flex flex-row flex-wrap align-items-center justify-content-around'>
                        <div>
                            <img src={clouds}/>
                            <p style={{ color: "#7F01FC", fontSize: "24px", fontWeight: "600" }}>Step 1</p>
                            <p className='stepsTitle' style={{ fontWeight: "700", fontSize: "40px",width:"350px" }}>Choose a test <img src={design}/>and click on play now to take participation in challenges</p>
                            <p className='stepsDesc' style={{ fontSize: "18px", color: "#959595" }}>You can also create your own test</p>
                        </div>
                        <div style={{ background:"#F7F5FB",borderRadius:"12px 0px 0px 12px"}}>
                            <div className='playteststepsimgwrap' style={{ padding: "50px 200px" }}>
                                <img src={design2} />
                                <img className='playteststepimg' src={playteststeps1} />
                            </div>
                        </div>
                    </div>

                </Carousel.Item>
                <Carousel.Item>
                    <div className='d-flex flex-row flex-wrap align-items-center justify-content-around'>
                        <div>
                            <img src={clouds}/>
                            <p style={{ color: "#7F01FC", fontSize: "24px", fontWeight: "600" }}>Step 2</p>
                            <p className='stepsTitle' style={{ fontWeight: "700", fontSize: "40px",width:"350px" }}>Win the challenge <img src={design}/>and get cash Rewards</p>
                            <p className='stepsDesc' style={{ fontSize: "18px", color: "#959595" }}>Pay the amount and Play the test</p>
                        </div>
                        <div style={{ background:"#F7F5FB",borderRadius:"12px 0px 0px 12px"}}>
                            <div className='playteststepsimgwrap' style={{ padding: "50px 200px" }}>
                                <img src={design2} />
                                <img className='playteststepimg' src={playteststeps2} />
                            </div>
                        </div>
                    </div>

                </Carousel.Item>
                <Carousel.Item>
                    <div className='d-flex flex-row flex-wrap align-items-center justify-content-around'>
                        <div>
                            <img src={clouds}/>
                            <p style={{ color: "#7F01FC", fontSize: "24px", fontWeight: "600" }}>Step 3</p>
                            <p className='stepsTitle' style={{ fontWeight: "700", fontSize: "40px" ,width:"350px"}}>Explore our<img src={design}/>Premium Courses from top educator</p>
                            <p className='stepsDesc' style={{ fontSize: "18px", color: "#959595" }}>Collect coins and use them to <br/> purchase these courses on discount</p>
                        </div>
                        <div style={{ background:"#F7F5FB",borderRadius:"12px 0px 0px 12px"}}>
                            <div className='playteststepsimgwrap' style={{ padding: "50px 200px" }}>
                                <img src={design2} />
                                <img className='playteststepimg' src={playteststeps3} />
                            </div>
                        </div>
                    </div>

                </Carousel.Item>
                <Carousel.Item>
                    <div className='d-flex flex-row flex-wrap align-items-center justify-content-around'>
                        <div>
                            <img src={clouds}/>
                            <p style={{ color: "#7F01FC", fontSize: "24px", fontWeight: "600" }}>Step 4</p>
                            <p className='stepsTitle' style={{ fontWeight: "700", fontSize: "40px",width:"350px" }}>Perform  <img src={design}/>Analysis of your challenges</p>
                            <p className='stepsDesc' style={{ fontSize: "18px", color: "#959595" }}>Get detailed insights of your test results</p>
                        </div>
                        <div style={{ background:"#F7F5FB",borderRadius:"12px 0px 0px 12px"}}>
                            <div className='playteststepsimgwrap' style={{ padding: "50px 200px" }}>
                                <img src={design2} />
                                <img className='playteststepimg' src={playteststeps4} />
                            </div>
                        </div>
                    </div>

                </Carousel.Item>
                <Carousel.Item>
                    <div className='d-flex flex-row flex-wrap align-items-center justify-content-around'>
                        <div>
                            <img src={clouds}/>
                            <p style={{ color: "#7F01FC", fontSize: "24px", fontWeight: "600" }}>Step 5</p>
                            <p className='stepsTitle' style={{ fontWeight: "700", fontSize: "40px" ,width:"350px"}}>Choose a test <img src={design}/>and click on play now to take participation in challenges</p>
                            <p className='stepsDesc' style={{ fontSize: "18px", color: "#959595" }}>You can also create your own test</p>
                        </div>
                        <div style={{ background:"#F7F5FB",borderRadius:"12px 0px 0px 12px"}}>
                            <div className='playteststepsimgwrap' style={{ padding: "50px 200px" }}>
                                <img src={design2} />
                                <img className='playteststepimg' src={playteststeps5} />
                            </div>
                        </div>
                    </div>

                </Carousel.Item>

            </Carousel>

        </>
    )
}

export default PlayTestSteps