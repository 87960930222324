import React from 'react';
import '../css/welcomeBonus.css'
import welcomebonus from '../pictures/welcomebonus.svg';
import downloadnow from '../pictures/downloadnow.svg';


function WelcomeBonus() {
  return (
    <>
      <div style={{ background: "#F7F5FB", marginTop: "50px" }}>
        <div className='welcomeWrapper d-flex flex-wrap align-items-center justify-content-around' style={{ padding: "40px 100px" }}>
          <div>
            <img style={{padding:"20px"}} src={welcomebonus} />
          </div>
          <div>
            <p style={{ color: "#959595", fontSize: "24px" }}>New to testwin?</p>
            <p style={{ fontWeight: "600", fontSize: "40px" }}>Get ₹ 1500 welcome bonus</p>
            <p style={{ color: "#959595", fontSize: "18px" }}>Take participate in daily challenges and win to Earn With Learn, <br />Start Earning now </p>
            <a href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US" style={{ textDecoration: "none" }}><img src={downloadnow} /></a>
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomeBonus