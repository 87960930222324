import React from 'react';
import '../css/hero.css';

import Faq from './Faq';
import Institute from './Institute';
import Newsletter from './Newsletter';
import Blogs from './Blogs';
import Students from './Students';
import Partners from './Partners';
import Social from './Social';
import PerformanceAnalysis from './PerformanceAnalysis';
import WelcomeBonus from './WelcomeBonus';
import PopularExams from './PopularExams';
import Leaderboard from './Leaderboard';
import Aspirant from './Aspirant';
import PlayTestSteps from './PlayTestSteps';
import InstallApp from './InstallApp';
import Learn from './Learn';

import hero from "../pictures/hero.svg";
import first from "../pictures/1st.png";
import getapplink from "../pictures/getapplink.svg";
import playstorelink from "../pictures/playstorelink.svg";
import appstorelink from "../pictures/appstorelink.svg";
import herobackground from "../pictures/herobackground.svg";



function HeroSection() {
    return (
        <>
            <section style={{ marginTop: "70px" }} id="hero" className="d-flex align-items-center">
                <div className="container p-3" style={{ background: "#F7F5FB", borderRadius: "22px 22px 0px 0px" }}>
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <h1 data-aos="fade-up" style={{ fontSize: "60px", fontWeight: "700" }}>Testwin <br />Learn, Play <span style={{ color: "#7F01FC" }}>&</span> Earn</h1>
                            <h2 className='mt-4' data-aos="fade-up" data-aos-delay="400" style={{ fontSize: "16px" }}>India's <span><img style={{ marginBottom: "8px" }} src={first} /></span> App which gives you real money for playing <br /> test of your desired course</h2>
                            <div className="text-center text-lg-start">
                                <div>
                                    <div className=" d-inline-flex align-items-center mt-3">
                                      
                                        <input style={{borderRadius:"8px",padding:"3px",margin:"2px",width:"200px"}} type="text" placeholder='Enter your mobile number'/>
                                    
                                        <a href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US"><img src={getapplink} height="34px" /></a>
                                    </div>
                                </div>
                                <div className='mt-5 mb-5'>
                                    <a href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US"><img className='m-1' src={playstorelink} /></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US"><img className='m-1' src={appstorelink} /></a>
                                </div>
                            </div>
                        </div>
                        <div class="heroMockupParent col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200" style={{ backgroundImage: `url(${herobackground})`, backgroundSize: "cover" }}>
                            <img style={{}} src={hero} alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <Aspirant />
            <PlayTestSteps />
            <WelcomeBonus />
            <InstallApp />
            <Learn />
            <Leaderboard />
            <PopularExams />
            <PerformanceAnalysis />
            <Students />
            <Partners />
            <Social />
            <Blogs />
            <Newsletter />
            <Faq />
            <Institute />
        </>
    )
}

export default HeroSection