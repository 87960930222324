import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import userreview from '../pictures/userreview.svg';
import userreview2 from '../pictures/userreview2.svg';
import userreview3 from '../pictures/userreview3.svg';

function Students() {
    return (
        <>
            <div className='d-flex flex-wrap justify-content-around align-items-center'>
                <div style={{textAlign:"center"}}> 
                    <p style={{ fontSize: "40px", fontWeight: "600" }}>What students say <br /> about Us.</p>
                    <p style={{ fontSize: "18px", fontWeight: "500", color: "#959595" }}>Our students love to <br /> win challenges <span style={{ color: "#7F01FC" }}>&</span> earn Real cash</p>
                </div>
                <div>
                    <Carousel fade variant="dark" slide={false}>
                        <Carousel.Item>
                            <div style={{border:"1px solid #F2F2F2",boxShadow:"1px 4px 10px rgba(0, 0, 0, 0.05)",borderRadius:"10px"}}>
                            <div style={{padding:"34px 70px 34px 20px"}}>
                                <p style={{fontSize:"17px",fontWeight:"400",color:"#959595"}}>Very helpful app to get chance of learning and<br/>testing knowledge with earnings.I won Rs.150, <br/>I highly appreciate this and recommend this<br/>to everyone to use it for better preparation. </p>
                                <div className='d-flex align-items-center justify-content-start'>
                                <img src={userreview}/>
                                <div className='ms-2'>
                                <p style={{margin:"0",marginTop:"20px"}}>Abhishekh</p>
                                <p style={{fontSize:"18px",fontWeight:"500",color:"#959595"}}>NEET Aspirant</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div style={{border:"1px solid #F2F2F2",boxShadow:"1px 4px 10px rgba(0, 0, 0, 0.05)",borderRadius:"10px"}}>
                            <div style={{padding:"34px 70px 34px 20px"}}>
                                <p style={{fontSize:"17px",fontWeight:"400",color:"#959595"}}>You won't find a app on playstore that rewards<br/> you instantly like Testwin does.<br/> They do what they<br/> claim to do.</p>
                                <div className='d-flex align-items-center justify-content-start'>
                                <img src={userreview2}/>
                                <div className='ms-2'>
                                <p style={{margin:"0",marginTop:"20px"}}>Apoorva</p>
                                <p style={{fontSize:"18px",fontWeight:"500",color:"#959595"}}>SSC Aspirant</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div style={{border:"1px solid #F2F2F2",boxShadow:"1px 4px 10px rgba(0, 0, 0, 0.05)",borderRadius:"10px"}}>
                            <div style={{padding:"34px 70px 34px 20px"}}>
                                <p style={{fontSize:"17px",fontWeight:"400",color:"#959595"}}>Very helpful app to get chance of learning and<br/>testing knowledge with earnings.I won Rs.150, <br/>I highly appreciate this and recommend this<br/>to everyone to use it for better preparation. </p>
                                <div className='d-flex align-items-center justify-content-start'>
                                <img src={userreview3}/>
                                <div className='ms-2'>
                                <p style={{margin:"0",marginTop:"20px"}}>Ripu</p>
                                <p style={{fontSize:"18px",fontWeight:"500",color:"#959595"}}>UPSC Aspirant</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </Carousel.Item>
                        </Carousel>
                </div>
            </div>
        </>
    )
}

export default Students