import React from 'react';
import '../css/newsletter.css'

function Newsletter() {
  return (
   <div className='m-3'>
   <div className='d-flex flex-column align-items-center justify-content-center' style={{padding:"30px",background:"#7F01FC",borderRadius:"20px 20px 0px 0px"}}>
     <div className='fw-bold' style={{fontSize:"30px",color:"white",maxWidth:"1000px"}}>Subscribe to our newsletter to get latest updates</div>
     <div className='inputContainer mt-4 d-flex align-items-center justify-content-center'>
        <input style={{padding:"17px 52px",borderRadius:"10px 0px 0px 10px"}} type="email" placeholder='Enter your Email id'/>
        <button style={{background:"#1B9645",color:"white",borderRadius:"0px 10px 20px 0px",border:"none",padding:"17px 39px"}}>Subscribe</button>
     </div>
   </div>
   </div>
  )
}

export default Newsletter