import React from 'react';
import '../css/installApp.css';
import Carousel from 'react-bootstrap/Carousel';
import installappbackground from '../pictures/installappbackground.svg';
import installappstep1 from '../pictures/installappstep1.svg';
import installappstep2 from '../pictures/installappstep2.svg';
import installappstep3 from '../pictures/installappstep3.svg';


function InstallApp() {
    return (
        <div className='installWrapper' style={{ marginTop: "50px", backgroundImage: `url(${installappbackground})`, backgroundSize: "cover" }}>
            <div className='d-flex flex-wrap align-items-center justify-content-around'>
                <div>
                    <p style={{ fontSize: "40px", fontWeight: "600" }}>Follow these steps to<br /> install our<br /> <span style={{ color: "#7F01FC" }}>Testwin</span> App</p>
                    <p style={{ fontSize: "18px", fontWeight: "500", color: "#959595", marginTop: "20px" }}>It's very easy to get started and attempt the<br /> test, just follow these instructions.</p>
                </div>
                <div className='mt-3'>
                    <Carousel variant="dark" slide={false}>
                        <Carousel.Item>
                            <div style={{background:"#F7F5FB",borderRadius:"12px"}}>
                            <img style={{margin:"20px 25px 20px 25px"}} src={installappstep1} alt="First slide"
                            />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={installappstep2} alt="second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <div style={{background:"#F7F5FB",borderRadius:"12px"}}>
                            <img style={{margin:"20px 25px 20px 25px"}} src={installappstep3} alt="third slide"
                            />
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default InstallApp

