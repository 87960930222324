import React from 'react'
import '../css/learn.css';
import learn from '../pictures/learn.svg';
import play from '../pictures/play.svg';
import earn from '../pictures/earn.svg';
import readourblogs from '../pictures/readourblogs.svg'

function Learn() {
    return (
        <div className='learnWrapper' style={{padding:"50px 90px"}}>
            <div style={{background:"#F7F5FB",borderRadius:"18px"}}>
                  <div className='d-flex flex-column align-items-center justify-content-center'>
                    <p style={{fontSize:"40px",fontWeight:"600"}}>What we give you</p>
                    <img src={readourblogs}/>
                  </div>
                <div className='m-5 d-flex flex-wrap align-items-center justify-content-around' id='divLearn' style={{}}>
                    <div id='container1' style={{ background: "white",padding:"29px 64px",margin:"5px",borderRadius:"12px" }}><img src={learn} /></div>
                    <div id='container2' style={{ background: "white",padding:"43px 76px",margin:"5px",borderRadius:"12px" }}><img src={play} /></div>
                    <div id='container3' style={{ background: "white" ,padding:"56px 70px" ,margin:"5px",borderRadius:"12px",marginBottom:"20px"}}><img src={earn} /></div>
                </div>
                <div id='div1'>kwefhwu eh ehdf hwjd hwgw wbddevw wvdgw dhwevfd wdfhwkf hejj deg dhgwnjd</div>
                <div id='div2'>kwefhwu eh ehdf hwjd hwgw wbddevw wvdgw dhwevfd</div>
                <div id='div3'>kwefhwu eh ehdf hwjd hwgw wbddevw wvdgw dhwevfd wdfhwkf hejj deg dhgwnjd ejfhe euf hh wdwd dwehdbhwefj</div>
            </div>
        </div>
    )
}

export default Learn