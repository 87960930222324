import React from 'react'
import institute from '../pictures/institute.svg'

function Institute() {
    return (
        <>
           <div className='d-flex flex-column justify-content-center align-items-center' style={{height:"300px",backgroundImage:`url(${institute})`,backgroundSize:"cover"}}>
            <div className='fw-bold' style={{fontSize: "22px",textAlign:"center"}}>Are you a Brand/Institute? Become a partner</div>
            <div><a href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US"><button style={{background:"#7F01FC" , color:"white", borderRadius:"10px", padding:"2px 23px", height:"49px",width:"200px", fontSize:"22px" , border:"none",marginTop:"30px"}}>Learn More</button></a></div>
           </div>
        </>
    )
}

export default Institute
