import React from 'react';
import logonavbar from '../pictures/logonavbar.svg';
import downloadapp from '../pictures/downloadapp.png';
import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <>
            <nav  class="navbar fixed-top navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid">
                    <div><img src={logonavbar} /></div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

                            <li class="nav-item">
                                <Link class="nav-link active" to="/">Home</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" to="/aboutus">About Us</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" to="/contact">Contact</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" to="/terms&conditions">Terms & Conditions</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" to="/privacypolicy">Privacy policy</Link>
                            </li>
                            <li class="nav-item">
                                <a style={{background:"#2532DB",borderRadius:"19px"}} class="nav-link" href="https://play.google.com/store/apps/details?id=com.testwin&hl=en_IN&gl=US"><span><img src={downloadapp}/></span> <span style={{color:"white"}}>Download App!!</span></a>
                            </li>
                        </ul>
                        <ul class="d-flex">

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar