import React from 'react'
import '../css/social.css'
import social1 from '../pictures/social1.png';
import social2 from '../pictures/social2.png';
import social3 from '../pictures/social3.png';
import social4 from '../pictures/social4.png';
import socialmedia1 from '../pictures/socialmedia1.svg';
import socialmedia2 from '../pictures/socialmedia2.svg';
import socialmedia3 from '../pictures/socialmedia3.svg';
import socialmedia4 from '../pictures/socialmedia4.svg';
import emoji from '../pictures/emoji.svg';

function Social() {
  return (
   <>
   <div className='socialWrapper d-flex flex-wrap align-items-center justify-content-around' style={{padding:"80px 80px",background:"#F7F5FB",borderRadius:"15px",marginLeft:"90px"}}>
          <div className='d-flex flex-column align-items-center justify-content-center'> 
          <div><img src={emoji}/></div>
          <div className='mt-4 fw-bold' style={{fontSize:"30px"}}>Let's get social</div>
          <div className='mt-3'>
            <img className="m-2" src={socialmedia1}/>
           <a href="https://www.instagram.com/testwin.play?igshid=MjljNjAzYmU="><img className="m-2"src={socialmedia2}/></a>
            <img className="m-2"src={socialmedia3}/>
            <img className="m-2"src={socialmedia4}/>
          </div>
          </div>
          <div className='socialImagesWrapper d-flex flex-wrap align-items-center justify-content-center'>
          <div className='socialhover m-3'> <img className='socialImages' src={social1}/></div>
          <div className='socialhover m-3'> <img className='socialImages' src={social2}/></div>
          <div className='socialhover m-3'> <img className='socialImages' src={social3}/></div> 
          <div className='socialhover m-3'> <img className='socialImages' src={social4}/></div> 
          </div>
    </div>
   </>
  )
}

export default Social